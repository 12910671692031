import { Data } from "./Consts";
import DisplayMeasure from "./Classes/DisplayMeasure";
import digitVersion from './Lib/digitVersion';

const maxG = 9;

function toMinFuel(item) {
  const newItem = new DisplayMeasure(item, 0);

  let max = newItem.values_mach.reduce((res, item) => (item.calcNy <= maxG && item.TurnRate > res.TurnRate ? item : res));

  let { Plane, Payload, Flaps, Version, Id } = newItem.src;
  return { Plane, Payload, Flaps, Version, Id, ...max };
}



let exceptId = [13, 19, 26, 7, 33, 24, 23, 111,112,55,40,59];
var d = Data.filter((item) => item.Altitude === 1000 && item.Fuel * 2 === item.TotalFuel && !exceptId.includes(item.Id))
  .map(toMinFuel)
  .reduce((res, item) => {
    let idx = res.findIndex(
      (value) => value.Plane === item.Plane && value.Payload?.toLowerCase() === item.Payload?.toLowerCase() && value.Flaps === item.Flaps
    );

    if (idx === -1) res.push(item);
    else if (digitVersion(res[idx].Version) < digitVersion(item.Version)) res[idx] = item;

    return res;
  }, []);

d.sort((b, a) => {
  if (a.TurnRate > b.TurnRate) return 1;
  if (a.TurnRate < b.TurnRate) return -1;

  return 0;
});

export default d;
