import React, { useRef, useState } from "react";
import "./App.css";
import { Select, Button, Table, Space, Row, Col } from "antd";
import { Layout, Divider } from "antd";
import { useParams } from "react-router-dom";
import { actualVersion, kinds, Data } from "./Consts";
import Graphic from "./Graphic";
import History from "./History";

import classNames from "classnames";

const { Column } = Table;

//https://stackoverflow.com/questions/19513955/how-to-document-a-dictionary-in-jsdoc
/** @type {Object.<string, string>} */
let kindDictionary = kinds.reduce((result, elem) => {
  elem.planes.forEach((plane) => (result[plane] = elem.name));
  return result;
}, {});

/**
 *
 * @template T
 * @template {string | number} Key
 * @param {T[]} array
 * @param {function(T):Key} groupFunction
 * @returns {Object.<Key, T[]>} Dictionary
 */
function groupBy(array, groupFunction) {
  return array.reduce((result, item) => {
    /** @type {string | number} */
    let groupKey = groupFunction(item);
    (result[groupKey] = result[groupKey] || []).push(item);
    return result;
  }, {});
}

/**
 *
 * @template T
 * @template {string | number} Key
 * @param {T[]} array
 * @param {function(T):Key} groupFunction
 * @returns {Map<Key, T[]>} Dictionary
 */
function groupBy2(array, groupFunction) {
  /** @type {Map<Key, T[]>} */
  let map = new Map();

  for (let element of array) {
    /** @type {string | number} */
    let groupKey = groupFunction(element);
    /** @type {T[]} */
    let groupArray = map[groupKey] || (map[groupKey] = []);
    groupArray.push(element);
  }

  return map;
}

/** @type {Object<number, import("./Consts").PlaneMeasure[]>} */
var planeByAltitudesOne = groupBy(Data, (plane) => Math.round(plane.Altitude / 100) * 100);

/** @type {Object<number, Object<string,import("./Consts").PlaneMeasure[]>>} */
var planeByAltitudes={};
for (let key in planeByAltitudesOne) planeByAltitudes[key] = groupBy(planeByAltitudesOne[key], (plane) => plane.Plane);

//var altitudeInfo = Object.keys(planeByAltitudes).map((altitude) => ({ altitude, kinds: availableKindsForAltitude(altitude) }));

var altitudeInfo2 = Object.keys(planeByAltitudes).reduce((result, altitude) => ((result[altitude] = availableKindsForAltitude(altitude)), result), {});

/**
 *
 * @param {number} altitude
 * @returns {string[]} массив типов самолётов
 */
function availableKindsForAltitude(altitude) {
  let foo = Object.keys(planeByAltitudes[altitude]).map((planeName) => kindDictionary[planeName]);

  return [...new Set(foo)];
}

/**
 *
 * @param {number} altitude
 * @param {string} kind
 * @returns {Array<import("./Consts").PlaneMeasure | *> }
 */
function getPlanes(altitude, kind) {
  if (!altitude || !kind) return null;
  let key = 0;



  var l = planeByAltitudes[altitude];

  let result = [];

  for (let Plane of Object.keys(l)) {
    
    if (kindDictionary[Plane] === kind) {

      let planes = l[Plane].filter(p=>p.Archive===false);

      if (planes.length > 1) result.push({ Plane, key: ++key, children: planes });
      else result.push(planes[0]);
    }
  }

  Object.keys(planeByAltitudes[altitude])
    .filter((Plane) => kindDictionary[Plane] === kind)
    .map((Plane) => (l[Plane].length > 1 ? { Plane, key: ++key, children: l[Plane] } : l[Plane][0]));


   
  return result;
}

const { Option } = Select;

///****************************************************************************************************************** */

function App({ config }) {
  let graphic = useRef();
  /** @type {{vals:string}}*/
  let location = useParams(); // useLocation();// window.location;

  let [select, setSelect] = useState({ alt: null, kind: null });

  function massStr(mass) {
    let c = 2.20462;
    return config.lbs ? Math.round(mass * c) + "lbs" : mass + "kg";
  }

  function AddItem(item) {
    graphic.current.AddItem(item);
  }

  return (
    <Layout>
      <Row gutter={[4, 4]}>
        <Col>
          <Space>
            Select Altitude:
            <Select
              value={select.alt}
              style={{ width: 140 }}
              onChange={(alt) => {
                //setSelect({ alt });
                let kind = "Main Planes";
                setSelect((prev) => ({ alt, kind, planes: getPlanes(alt, kind) }));
                graphic.current.Reset();
                //setSelected([]); TODO
              }}>
              {Object.keys(planeByAltitudes).map((i) => (
                <Option key={i} value={i.toString()}>
                  {i}m/{Math.round(+i * 3.2808399)}ft
                </Option>
              ))}
            </Select>
            Select Plane type:
            <Select
              allowClear
              value={select.kind}
              onChange={(kind) => setSelect((prev) => ({ ...prev, kind, planes: getPlanes(prev.alt, kind) }))}
              placeholder="Select plane type">
              {altitudeInfo2[select.alt]?.map((item, i) => (
                <Option key={i} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        {!(select.planes || location.vals) && (
          <Col sm={24}>
            <div style={{ width: "1000px" }}>
              <Divider>Last FM chagers (1.03.2024)</Divider>
            </div>
            <History config={config} />
          </Col>
        )}

        {select.planes && (
          <Col sm={24}>
            <h3>Select</h3>
            <div style={{ paddingBottom: "8px", width: "1000px" }}>
              <Table size="small" rowClassName={() => "PlaneListRow"} bordered={true} pagination={false} dataSource={select.planes}>
                <Column title="Plane" dataIndex="Plane" key="Id" render={(text, record, index) => text.replace(/\s/g, "11")} />
                <Column title="Mass" dataIndex="Mass" render={(item, row) => <div>{row.Mass && massStr(row.Mass)}</div>} />

                <Column
                  title="Fuel"
                  dataIndex="Fuel"
                  render={(item, row) => <div>{row.Fuel && massStr(row.Fuel) + " (" + Math.round((row.Fuel / row.TotalFuel) * 100) + "%)"}</div>}
                />

                <Column
                  title="Flaps"
                  dataIndex="Flaps"
                  render={(v) => <span className={classNames({ StandartValue: ["Off", "Auto"].includes(v) })}>{v}</span>}
                />

                <Column
                  title="Payload"
                  dataIndex="Payload"
                  render={(v) => <span className={classNames({ StandartValue: ["Nozzle 0"].includes(v) })}>{v}</span>}
                />

                <Column title="Version" dataIndex="Version" render={(v,record) =>
                  
                 <span className={classNames({ActualVersion:actualVersion.includes(v) || actualVersion.includes(record.Actual) })}>{v}{(record.Actual ? "→"+record.Actual:"")}</span>} />

                <Column
                  title=""
                  render={(text, record, index) => (
                    <Space>
                      <Button block={true} type={record.children ? "default" : "dashed"} onClick={() => AddItem(record)}>
                        Add
                      </Button>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </Col>
        )}

        <Col sm={24}>
          <Graphic title="Selected" initValues={location.vals} ref={graphic} config={config} />
        </Col>
      </Row>
    </Layout>
  );
}

export default App;
