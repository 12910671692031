/**
 * 
 * @param {string} str 
 * @returns number
 */
 export default function digitVersion(str) {
    const pows = [8,7,5,0];
    let numbers  = str.split(".").map((s)=>+s);
    return numbers.reduce((res, item,idx) => res+item * 10**pows[idx],0)
  }
  