import React, { useState, useEffect } from "react";
import "./App.css";
import { Select, Switch, Menu, Divider } from "antd";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { speedType } from "./Consts";

import App from "./App";
import About from "./About";
import History from "./History";
import Diagram from "./Diagram";
import RatRace from "./RatRace";

import { Switch as Sw, Route, Link, useLocation } from "react-router-dom";

import { HistoryOutlined, QuestionCircleOutlined, AreaChartOutlined, DatabaseOutlined, OrderedListOutlined } from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

const { Content } = Layout;
const { Option } = Select;

const DARK_MODE = "dark-mode";

///****************************************************************************************************************** */

function Main() {
  /** @type {{vals?:string}}*/
  let location = useParams(); // useLocation();// window.location;

  let [Dark, setDark] = useState(() => JSON.parse(localStorage.getItem(DARK_MODE)) || false);

  let [config, setConfig] = useState(() => {
    let queryParams = location.vals?.split(",").map((item) => item.toLowerCase()) || [];

    let speed = speedType.findIndex((item) => queryParams.includes(item.key.toLowerCase()));
    if (speed === -1) speed = JSON.parse(localStorage.getItem("speed")) || 0;

    let zoom = queryParams.includes("zoom") || JSON.parse(localStorage.getItem("zoom")) || false;
    let lbs = queryParams.includes("lbs") || JSON.parse(localStorage.getItem("lbs")) || false;

    return { speed, zoom, lbs };
  });

  function updateConfig(newValues) {
    setConfig({ ...config, ...newValues });
  }

  let route = useLocation();

  let { pathname } = route;
  if (!isNaN(pathname[1])) pathname = "/";

  let subs = ["History", "Diagram"];
  var sub = pathname.split("/")[1];

  useEffect(() => {
    var main = document.getElementsByClassName("main")[0];
    if (main) main.scrollTop = 0;
  }, [pathname]);

  // console.log("route.pathname",route.pathname,route)

  const Diagrams = [
    { name: "Mig-29a", location: "/Diagram/Mig29" },
    { name: "Su-27", location: "/Diagram/Su27" },
    { name: "F-15c", location: "/Diagram/F15c" },
    { name: "F-14B", location: "/Diagram/F14B" },
    { name: "F-16C", location: "/Diagram/F16C" },
    { name: "F/A-18C", location: "/Diagram/Fa18c" },
    { name: "Mirage-2000C", location: "/Diagram/Mirage2000C" },
    { name: "F/A-18C (2)", location: "/Diagram/Fa18c_2", highlight: true },
    { name: "Mig-29a (2)", location: "/Diagram/Mig29_2", highlight: true },
  ];

  return (
    <Layout  theme="light" style={{ height: "100vh" }} >
      {Dark ? <link rel="stylesheet" href="/antd.dark.min.css" /> : <link rel="stylesheet" href="/antd.min.css" />}

      <Sider theme={Dark ? "dark" : "light"} width={200} style={{ overflowY: "auto", overflowX: "hidden", height: "100vh" }}>
        <Menu mode="inline" defaultSelectedKeys={[pathname]} defaultOpenKeys={[sub]} style={{ borderRight: 0 }}>
          <Menu.Item key="/" icon={<DatabaseOutlined />}>
            <Link to="/">All data</Link>
          </Menu.Item>

          <SubMenu key="Diagram" icon={<AreaChartOutlined />} title="Diagram Compare">
            {Diagrams.map((item) => (
              <Menu.Item key={item.location}>
                <Link to={item.location} className={item.highlight ? "highlight" : ""}>{item.name}</Link>
              </Menu.Item>
            ))}
          </SubMenu>

          <SubMenu key="History" icon={<HistoryOutlined />} title="History">

      

          <Menu.Item key="/History/28.05.2023">
              <Link to="/History/28.05.2023">
                <b>28.05.2023</b>
              </Link>
            </Menu.Item>

          
          <Menu.Item key="/History/26.09.2022">
              <Link to="/History/26.09.2022">
                26.09.2022
              </Link>
            </Menu.Item>

          <Menu.Item key="/History/02.09.2022">
              <Link to="/History/02.09.2022">
                02.09.2022
              </Link>
            </Menu.Item>

            <Menu.Item key="/History/09.08.2022">
              <Link to="/History/09.08.2022">
                09.08.2022
              </Link>
            </Menu.Item>
          

          <Menu.Item key="/History/12.07.2022">
              <Link to="/History/12.07.2022">
                12.07.2022
              </Link>
            </Menu.Item>

          <Menu.Item key="/History/28.06.2022">
              <Link to="/History/28.06.2022">
                28.06.2022
              </Link>
            </Menu.Item>
          

          <Menu.Item key="/History/29.11.2021">
              <Link to="/History/29.11.2021">
                29.11.2021
              </Link>
            </Menu.Item>


            <Menu.Item key="/History/15.11.2021">
              <Link to="/History/15.11.2021">
                15.11.2021
              </Link>
            </Menu.Item>

            <Menu.Item key="/History/23.10.2021">
              <Link to="/History/23.10.2021">23.10.2021</Link>
            </Menu.Item>

            <Menu.Item key="/History/19.09.2021">
              <Link to="/History/19.09.2021">19.09.2021</Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="/RatRace" icon={<OrderedListOutlined />}>
            <Link to="/RatRace">Rat Race</Link>
          </Menu.Item>

          <Menu.Item key="/About" icon={<QuestionCircleOutlined />}>
            <Link to="/About">About</Link>
          </Menu.Item>
        </Menu>

        <Divider />

        <div className="ConfigGrid">
          <div>Speed:</div>
          <div>
            <Select
              listHeight={480}
              style={{ width: 120 }}
              showArrow={true}
              value={config.speed}
              onChange={(speed) => {
                localStorage.setItem("speed", speed);
                updateConfig({ speed });
              }}>
              {speedType.map((item, i) => (
                <Option key={i} value={i}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>

          <div>Pounds:</div>
          <div>
            <Switch
              checked={config.lbs}
              onChange={(lbs) => {
                localStorage.setItem("lbs", lbs);
                updateConfig({ lbs });
              }}
            />
          </div>

          <div>Zoom:</div>
          <div>
            <Switch
              checked={config.zoom}
              onChange={(zoom) => {
                localStorage.setItem("zoom", zoom);
                updateConfig({ zoom });
              }}
            />
          </div>

          <div>Dark</div>
          <div>
            <Switch
              checked={Dark}
              onChange={(Dark) => {
                localStorage.setItem(DARK_MODE, Dark);
                setDark(Dark);
              }}
            />
          </div>
        </div>
      </Sider>

      <Content className="main" style={{ overflowY: "auto", height: "100vh" }}>
        <Sw>
          <Route path="/test">
            <div>test</div>
          </Route>

          <Route path="/About">
            <About />
          </Route>

          <Route path="/RatRace">
            <RatRace />
          </Route>

          <Route path="/Diagram/:page">
            <Diagram config={config} />
          </Route>

          <Route path="/History/:date">
            <History config={config} />
          </Route>

          <Route path="/:vals?">
            <App config={config} />
          </Route>
        </Sw>
      </Content>
    </Layout>
  );
}

export default Main;
