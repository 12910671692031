//const DisplayMeasure = require("./DisplayMeasure");

import DisplayMeasure from "./DisplayMeasure"

const G = 9.80665;
const rho0 = 1.225; // All SI units
const sigFig = 1000;

/**
 *
 * @param {import("../Consts").PlaneMeasure} parent
 * @param {import("../Consts").PlaneStep} item
 * @param {*} param2
 * @param {number} CalcFuel
 * @returns {import("./DisplayMeasure").DisplayMeasureStep}
 */
function recalc(parent, item, { sonicSI, rhoSI }, CalcFuel) {
  // item.TAS = item.IAS*Math.pow((rho0/rhoSI),0.5)

  let { TAS, IAS, mach, roll,aoa,flaps } = item;

  if (!TAS && !TAS && mach) {
    // Скорость зада в махах
    TAS = mach * sonicSI * 3.6;
    IAS = TAS / Math.pow(rho0 / rhoSI, 0.5);
  } else if (!TAS) {
    // Скорость задана в IAS
    TAS = IAS * Math.pow(rho0 / rhoSI, 0.5);
    mach = TAS / sonicSI / 3.6;
  }

  const TASms = TAS / 3.6;

  const IAS_kts = IAS / 1.852;
  const TAS_kts = TAS / 1.852;

  // Расчёт перегрузки по скорости и скорости виража item.turn_rate и TASms
  //  if (!item.DefaultNy) item.DefaultNy = Math.sqrt(Math.pow(((item.turn_rate * TASms) / G / 180) * Math.PI, 2) + 1);

  /** @type {number} Перегрузка при начальной массе */
  let DefaultNy;
  if (item.GraphG) DefaultNy = item.GraphG / Math.cos((aoa * Math.PI) / 180);
  else DefaultNy = Math.sqrt(Math.pow(((item.turn_rate * TASms) / G / 180) * Math.PI, 2) + 1);

  // Расчёт перегрузки по заданной массе
  let calcNy = (DefaultNy * parent.Mass) / (parent.Mass - parent.Fuel + CalcFuel);

  // Расчёт скорости виража по перегрузке и скорости
  const TurnRate = (((G * Math.sqrt(Math.pow(calcNy, 2) - 1)) / TASms) * 180) / Math.PI;

  const TurnTime = 360 / TurnRate;

  const Radius = Math.pow(TASms, 2) / (G * Math.sqrt(Math.pow(calcNy, 2) - 1));

  if (aoa) calcNy = calcNy * Math.cos((aoa * Math.PI) / 180);

  if (!roll) roll = (Math.atan((TASms * TASms) / G / Radius) / Math.PI) * 180;

  return {IAS,IAS_kts,TAS,TAS_kts,mach, TurnTime, TurnRate, calcNy, Radius, aoa, roll, flaps}
}

/**
 *
 * @param {import("../Consts").PlaneMeasure}  data
 * @param {number} CalcFuel Расчётное количество топлива
 * @returns {import("./DisplayMeasure").DisplayMeasureStep[]} Данные для новой массы
 */
function recalcPlane(data,CalcFuel) {
  let atm = atmCalc(data.Altitude);

  return data.values.map((item) => recalc(data, item, atm,CalcFuel));
}

function atmCalc(altRaw) {
  //	var altRaw = 1000;
  var tempRaw = 0;
  var altSI = altRaw;

  var altitudeArray = [0, 11000, 20000, 32000, 47000, 51000, 71000, 84852];
  var presRelsArray = [
    1, 2.23361105092158e-1, 5.403295010784876e-2, 8.566678359291667e-3, 1.0945601337771144e-3, 6.606353132858367e-4, 3.904683373343926e-5,
    3.6850095235747942e-6,
  ];

  var tempsArray = [288.15, 216.65, 216.65, 228.65, 270.65, 270.65, 214.65, 186.946];
  var tempGradArray = [-6.5, 0, 1, 2.8, 0, -2.8, -2, 0];

  var i = 0;
  while (altSI > altitudeArray[i + 1]) {
    i = i + 1;
  }

  // i defines the array position that I require for the calc
  var alts = altitudeArray[i];
  var presRels = presRelsArray[i];
  var temps = tempsArray[i];
  var tempGrad = tempGradArray[i] / 1000;

  var deltaAlt = altSI - alts;
  var stdTemp = temps + deltaAlt * tempGrad; // this is the standard temperature at STP

  var tempSI = stdTemp + tempRaw; // includes the temp offset
  var tempDegC = tempSI - 273.15;
  var roundTSI = Math.round(sigFig * tempSI) / sigFig;
  var roundTDeg = Math.round(sigFig * tempDegC) / sigFig;

  // Now for the relative pressure calc:

  //define constants
  var airMol = 28.9644;
  var rhoSL = 1.225; // kg/m3
  var pSL = 101325; // Pa
  var tSL = 288.15; //K
  var gamma = 1.4;
  var g = 9.80665; // m/s2
  var stdTempGrad = -0.0065; // deg K/m
  var rGas = 8.31432; //kg/Mol/K
  var R = 287.053;
  var gMR = (g * airMol) / rGas;
  var dryMM = 0.0289644; //kg/mol
  var adLapse = 0.00198; //K/ft

  var relPres =
    Math.abs(tempGrad) < 1e-10 ? presRels * Math.exp((-1 * gMR * deltaAlt) / 1000 / temps) : presRels * Math.pow(temps / stdTemp, gMR / tempGrad / 1000);

  // Now I can calculate the results:

  let sonicSI = Math.sqrt(gamma * R * tempSI);
  let pressureSI = pSL * relPres;
  let rhoSI = rhoSL * relPres * (tSL / tempSI);
  let sigma = rhoSI / rhoSL;

  return { sonicSI, pressureSI, rhoSI, sigma };
} // end atmCalc()

//module.exports = 
export default  recalcPlane;
