import React from "react";

import Graphic from "./Graphic";
import { Image, Divider } from "antd";
import { useParams } from "react-router-dom";

import { XAxis, YAxis, CartesianGrid, Tooltip, Line, ReferenceLine, LineChart } from "recharts";

export default function ({ config }) {
  /** @type {{page:string}} */
  let location = useParams();
  var { page } = location;

  const pages = {
    F15c: (
      <>
        <h3>App B Perf Data F100-PW-220</h3>
        <Image src="/1f-15a-1_p1.jpg" width="640px"></Image>
        <div>Page B9-33</div>
        <Image src="/1f-15a-1_pB3-33.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="63,81,calcny" statical title="F-15c Clean, 37000 Pounds" config={config} />
      </>
    ),

    Mig29: (
      <>
        <h3>Практическая аэродинамика самолёта Миг-29</h3>
        <Image src="/pr_aerod_29_001.jpg" width="640px"></Image>
        <div>Page 203</div>
        <Image src="/pr_aerod_29_203.jpg" width="640px"></Image>
        <div>
          <a target="_blank" href="/DCS FM principles plus MiG-29 P-47 F-16-1.pdf">
            DCS FM principles plus MiG-29 P-47 F-16-1.pdf
          </a>{" "}
          document, page 13
        </div>
        <Image src="/pr_aerod_29_203_DCS.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="86,80,calcny" statical title="Mig-29 Clean, 13000kg" config={config} />
      </>
    ),

    Su27: (
      <>
        <h3>Самолёт Су-27СК, Руководсво по летной эксплуатации</h3>
        <Image src="/su-27rle-p1.jpg" width="640px"></Image>
        <div>Page 46</div>
        <Image src="/su-27rle-p46.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="61,83,calcny" statical title="Su-27 2xP27 2xP73, 29000kg" config={config} />
      </>
    ),

    F14B: (
      <>
        <h3>NAVAIR 01-F14AAP-1.1</h3>
        <Image src="/01-F14AAP-1.1-d1.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="158,93,turnrate" statical title="F-14B 55620lbs 4xAIM7 4xAIM9" config={config} />

        <div>Prev versions</div>
        <Graphic initValues="88,93,122,turnrate" statical title="F-14B 55620lbs 4xAIM7 4xAIM9" config={config} />
      </>
    ),

    F16C: (
      <>
        <h3>T.O. GR1F-16CJ-1-1</h3>
        <Image src="/HAF-F16-Supplement-001.jpg" width="640px"></Image>
        <Divider />
        <h3>Turn Performance - sea level, Max AB</h3>
        <Image src="/HAF-F16-Supplement-450.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="146,94,turnrate" statical title="F-16C Clean" config={config} />
        <Divider />
        <h3>Turn Performance - 5000 Feet, Max AB</h3>
        <Image src="/HAF-F16-Supplement-451.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="145,95,turnrate" statical title="F-16C Clean" config={config} />
        <Divider />
        <h3>Turn Performance - 10000 Feet, Max AB</h3>
        <Image src="/HAF-F16-Supplement-452.jpg" width="640px"></Image>
        <div>Comparison in game</div>
        <Graphic initValues="147,96,turnrate" statical title="F-16C Clean" config={config} />
      </>
    ),

    Fa18c: (
      <>
        <h3>GAO/NSIAD-96-98 Navy Aviation</h3>
        <Image src="/nsiad-96-98-001.png" width="640px"></Image>
        <div>Page 29</div>
        <Image src="/nsiad-96-98-029.png" width="640px"></Image>
        <div>Page 30</div>
        <Image src="/nsiad-96-98-030.png" width="640px"></Image>
        <br />
        <br />3
        <div>
          Check that <em>'2 AIM-9, 2 AIM-120, 60 percent fuel'</em> equal <b>33325</b> lbs weight. (Big thanks to <b>HDpilot</b>)
        </div>
        <div>
          <Image src="/navair1.png" width="640px"></Image>
        </div>
        <div>
          <Image src="/navair2.png" width="640px"></Image>
        </div>
        <div>Comparison in game at sea level</div>
        <Graphic
          initValues="97,turnrate"
          addon={{
            turnrate: (
              <ReferenceLine
                y={19.2}
                label={{
                  position: "bottom",
                  value: "19.2 deg/sec",
                  fill: "#ED7D31",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                isFront={true}
                strokeWidth={3}
                stroke="#ED7D31"
              />
            ),
            turntime: (
              <ReferenceLine
                y={18.75}
                label={{
                  position: "top",
                  value: "18.75 second",
                  fill: "#ED7D31",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                isFront={true}
                strokeWidth={3}
                stroke="#ED7D31"
              />
            ),
          }}
          statical
          title="F/A-18C 33325lbs 2xAIM-9 2xAIM-120, sea level"
          config={config}
        />
        <div>Comparison in game at 15000 feet</div>
        <Graphic
          initValues="98,turnrate"
          addon={{
            turnrate: (
              <ReferenceLine
                y={12.3}
                label={{
                  position: "bottom",
                  value: "12.3 deg/sec",
                  fill: "#ED7D31",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                isFront={true}
                strokeWidth={3}
                stroke="#ED7D31"
              />
            ),
            turntime: (
              <ReferenceLine
                y={29.27}
                x={1}
                label={{
                  position: "top",
                  value: "29.27 second",
                  fill: "#ED7D31",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                isFront={true}
                strokeWidth={3}
                stroke="#ED7D31"
              />
            ),
          }}
          statical
          title="F/A-18C 33325lbs 2xAIM-9 2xAIM-120"
          config={config}
        />
      </>
    ),

    F15cVsSu27: (
      <>
        <h3>Самолёт Су-27СК, Руководсво по летной эксплуатации, page 46</h3>
        <Image src="/su-27rle-p46.jpg" width="640px"></Image>
        <h3>App B Perf Data F100-PW-220, page B9-33</h3>
        <Image src="/1f-15a-1_pB3-33.jpg" width="640px"></Image>
        <div>Сompare TWO diagrams with EQUAL fuel quantity (1500kg)</div>
        <Graphic initValues="81-1500,83-1500,calcny" statical title="F-15c vs Su-27sk, EQUAL fuel quantity (1500kg)" config={config} />
        <div>Comparison in game</div>
        <Graphic initValues="63-1500,61-1500,calcny" statical title="F-15c vs Su-27sk, EQUAL fuel quantity (1500kg)" config={config} />
      </>
    ),

    Mirage2000C: (
      <>
        <h3>FWS-4-88</h3>
        <Image src="/Mirage2000at15k.jpg" width="640px"></Image>

        <div>Comparison in game</div>
        
        <Graphic initValues="149,104,turnrate" statical title="Mirage-2000" config={config} />

        <Divider />
        <div>Old version (2.7.16.28157) Comparison</div>
        <Graphic initValues="138,104,turnrate" statical title="Mirage-2000" config={config} />

        <Divider />
        <div>Old version (2.7.7.15038) Comparison</div>
        <Graphic initValues="103,104,turnrate" statical title="Mirage-2000" config={config} />
      </>
    ),

    Mig29_2: (
      <>
        <h3>FLIGHT MANUAL MIG-29</h3>
        
        <div><Image src="/mig-29(2)1.jpg" width="640px"></Image></div>
        <div><Image src="/mig-29(2)2.jpg" width="640px"></Image></div>
        <div><Image src="/mig-29(2)3.jpg" width="640px"></Image></div>

        <div>Comparison in game</div>
        <Graphic initValues="86,107,calcny" statical title="Mig-29a" config={config} />
      </>
    ),

    Fa18c_2: (
      <>
        <h3>15000 Feet altitude</h3>
        <div><Image src="/Fa-18c(2).jpg" width="640px"></Image></div>

        <div>Comparison in game</div>
        <Graphic initValues="105,106,turnrate" statical title="F/A-18c, 60% fuel with 3 fuel tanks" config={config} />
      </>
    ),

    //
  };

  return <div key={page}>{pages[page] || <>no data</>}</div>;
}
