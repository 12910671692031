import React from "react";

import Graphic from "./Graphic";
import { useParams } from "react-router-dom";
import { Divider } from "antd";

import { Link } from "react-router-dom";

export default function ({ config }) {
  /** @type {{date:string}}*/
  let location = useParams();
  var { date } = location;

  date = date || "01.03.2024";

  var history = {
    "19.09.2021": (
      <>
        <h3>F-16C Changes</h3>
        <Graphic initValues="76,69" statical title="F-16C Clean" config={config} />
        <Divider />
        <div>without pylons</div>
        <Graphic initValues="70,77" statical title="F-16C Clean, without pylons" config={config} />
      </>
    ),

    "23.10.2021": (
      <>
        <h3>F-14B Changes</h3>
        <Graphic initValues="84,51,turnrate" statical title="F-14B Clean" config={config} />
        <Divider />
        <h3>F-14A-135-GR</h3>
        <Graphic initValues="85,52,turnrate" statical title="F-14A-135-GR Clean" config={config} />
      </>
    ),

    "15.11.2021": (
      <>
        <h3>
          Add MiG-21Bis with <b>emergency afterburner</b>
        </h3>
        <Graphic initValues="74,101,turnrate" statical title="MiG-21Bis" config={config} />
        <Divider />
        <h3>
          F-14B with <b>Flaps</b>
        </h3>
        <Graphic initValues="99,100,turnrate" statical title="F-14B" config={config} />
      </>
    ),

    "29.11.2021": (
      <>
        <h3>AV8BNA</h3>
        <Graphic initValues="56,110,turnrate" statical title="AV8BNA Auto flaps" config={config} />
        <Divider />
        <h3>Cruise flaps</h3>
        <Graphic initValues="59,111,turnrate" statical title="AV8BNA Cruise flaps" config={config} />
        <div>
          <br />
          For example: <br />
          With 1000kg fuel AV8BNA are better than FA-18C, before 0.6Mach
        </div>
        <Graphic initValues="110-1000,73-1000,turnrate" statical title="AV8BNA Cruise flaps" config={config} />
      </>
    ),

    "28.06.2022": (
      <>
        <h3>F-16C Changes</h3>

        <p>
          <Link to="/Diagram/F16C">Added v.2.7.15.25026 for "Diagram Compare"</Link>
        </p>

        <Graphic initValues="116,76,turnrate" statical title="F-16C Clean" config={config} />
        <Divider />
        <div>without pylons</div>
        <Graphic initValues="115,77,turnrate" statical title="F-16C Clean, without pylons" config={config} />
        <Divider />

        <h3>F-14B Changes</h3>
        <p>
          <Link to="/Diagram/F14B">Added v.2.7.15.25026 for "Diagram Compare"</Link>
        </p>
        <Graphic initValues="120,84,turnrate" statical title="F-14B Clean" config={config} />
        <h3>with Flaps</h3>
        <Graphic initValues="121,100,turnrate" statical title="F-14B Clean with Flaps" config={config} />

        <Divider />

        <h3>JF-17 Changes</h3>
        <Graphic initValues="123,72,turnrate" statical title="JF-17 Clean" config={config} />

        <Divider />

        <h3>AV8BNA Changes</h3>
        <Graphic initValues="124,110,turnrate" statical title="AV8BNA Clean" config={config} />
      </>
    ),

    "12.07.2022": (
      <>
        <h3>AJS37</h3>
        <Graphic initValues="125,1,turnrate" statical title="AJS37" config={config} />
        <Divider />
        <h3>JF-17 with Full Flaps</h3>
        <Graphic initValues="129,19,turnrate" statical title="JF-17" config={config} />
        <Divider />
        <h3>F-14A-135-GR</h3>
        <Graphic initValues="133,85,turnrate" statical title="F-14A-135-GR" config={config} />
        <Divider />

        <h3>F/A-18C Hornet</h3>
        <Graphic initValues="135,73,turnrate" statical title="FA-18C" config={config} />
        <h3>F/A-18C Hornet without Pylons</h3>
        <Graphic initValues="136,71,turnrate" statical title="FA-18C without Pylons" config={config} />
        <Divider />

        <h3>AV8BNA</h3>
        <Graphic initValues="126,111,turnrate" statical title="AV8BNA with Cruise Flaps" config={config} />
        <Divider />  
        <Graphic initValues="127,112,turnrate" statical title="AV8BNA with STOL Flaps" config={config} />
        <Divider />
        <Graphic initValues="128,113,turnrate" statical title="AV8BNA, 4xAIM-9, Gun (NAVAIR 00-110AV8-4, Configuration 10)" config={config} />


      </>
    ),

    "09.08.2022": (
      <>
       <h3>M-2000C</h3>
        <Graphic initValues="137,54,turnrate" statical title="M-2000C" config={config} />
        <Divider />
        <Graphic initValues="138,104,turnrate" statical title="M-2000C, 2 x Magic2" config={config} />
      </>

    ),

    
    "02.09.2022": (
      <>
       <h3>Add MiG-19P</h3>
        <Graphic initValues="140,139,141" statical title="MiG-19P" config={config} />
        <Divider/>
        <h3>F-16C Changes</h3>
        <Graphic initValues="144,116,turnrate" statical title="F-16C Clean" config={config} />
        <Divider />
        <div>without pylons</div>
        <Graphic initValues="143,115,turnrate" statical title="F-16C Clean, without pylons" config={config} />
        
      

      </>

    ),

    "26.09.2022": (
      <>
       <h3>M-2000C</h3>
        <Graphic initValues="148,137,turnrate" statical title="M-2000C" config={config} />
        <Divider />
        <Graphic initValues="149,104,turnrate" statical title="M-2000C, 2 x Magic2" config={config} />
      </>

      

    ),

    "28.05.2023": (
      <>
       <h3>AJS37</h3>
        <Graphic initValues="154,125,turnrate" statical title="AJS37" config={config} />
        <Divider />
        <h3>F-14A-135-GR</h3>
        <Graphic initValues="155,133,turnrate" statical title="F-14A-135-GR" config={config} />

        <Divider />
        <h3>F-14B</h3>
        <Graphic initValues="156,120,turnrate" statical title="F-14B" config={config} />

        <Divider />
        <p>
          <Link to="/Diagram/F14B">Added v.2.8.5.40170 for "Diagram Compare"</Link>
        </p>

        <Divider />
        <h3>F-14B with Flaps</h3>
        <Graphic initValues="157,121,turnrate" statical title="F-14B with Flaps" config={config} />

        <Divider />
        <h3>JF-17</h3>
        <Graphic initValues="159,123,turnrate" statical title="JF-17" config={config} />

        <Divider />
        <h3>JF-17 with Flaps</h3>
        <Graphic initValues="159,129,turnrate" statical title="JF-17 with Flaps" config={config} />

        
      </>

      

    ),

    "01.03.2024": (
      <>
      <h3>Add some tests for Mirage-F1CE</h3>

      <Graphic initValues="171,172,173,turnrate" statical title="Mirage-F1CE" config={config} />
      
      <Divider />

      <h1>Refresh some data... FA-18C, F-16C, Su-27, MiG-29A, M-2000C, F-14B, Su-33</h1>
      </>
    ),

  };

  return <div key={date}>{history[date] || <>No data</>} </div>;
}
