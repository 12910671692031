//const spline = require("./Aproximation");
//const recalcPlane = require("./wrapper");

import spline from "./Aproximation"
import recalcPlane from "./wrapper"

/**
 * @typedef {Object} DisplayMeasureStep
 * @property {number} IAS
 * @property {number} IAS_kts
 * @property {number} TAS
 * @property {number} TAS_kts
 * @property {number} mach
 * @property {number} TurnTime
 * @property {number} TurnRate
 * @property {number} calcNy
 * @property {number} Radius
 * @property {number} [aoa]
 * @property {number} roll
 * @property {number} [flaps]
 */

class DisplayMeasure {
  /**
   *
   * @param {import("../Consts").PlaneMeasure} src
   * @param {number} [fuel]
   */
  constructor(src, fuel) {
    this.src = src;
    this.SetFuelCount(fuel);
  }

  SetFuelCount(fuelCount) {
    if (fuelCount === undefined) this.CalcFuel = this.src.Fuel;
    else this.CalcFuel = fuelCount;
    let calculatedValues = recalcPlane(this.src, this.CalcFuel);


    let vals = ["TurnTime", "TurnRate", "calcNy", "Radius", "aoa", "roll", "flaps"];

    /** @type {DisplayMeasureStep[]}*/
    this.values_IAS = spline(calculatedValues, "IAS", vals, 10); //newItem.values;
    /** @type {DisplayMeasureStep[]}*/
    this.values_IAS_kts = spline(calculatedValues, "IAS_kts", vals, 5);
    /** @type {DisplayMeasureStep[]}*/
    this.values_TAS = spline(calculatedValues, "TAS", vals, 10);
    /** @type {DisplayMeasureStep[]}*/
    this.values_TAS_kts = spline(calculatedValues, "TAS_kts", vals, 5);
    /** @type {DisplayMeasureStep[]}*/
    this.values_mach = spline(calculatedValues, "mach", vals, 0.01);
  }

  ResetFuelCount(){
      this.SetFuelCount();
  }
}

//module.exports = 

export default  DisplayMeasure;
