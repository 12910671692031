/** @type {PlaneMeasure[]} */
const Data = require("./data.json");

const actualVersion = ["2.9.3.51704"];

const actualSet = [
  { version: "2.7.15.26783", actuals: [7, 6, 9, 10, 54, 74, 101, 87, 26, 33, 32, 34, 35, 39, 86, 115, 116] },
  { version: "2.8.5.40170", actuals: [144, 148, 135] },
];

let defColors = ["#4472C4", "#ED7D31", "#A5A5A5", "#FFC000", "#5B9BD5", "#70AD47", "#264478", "#9E480E"];

let tabs = [
  { name: "Turn Time", desc: "Turn Time (sec)", key: "TurnTime" },
  { name: "Turn Rate", desc: "Turn Rate (degree/sec)", key: "TurnRate" },
  { name: "Ny", desc: "Ny", key: "calcNy" },
  { name: "Turn Radius", desc: "Turn Radius (meters)", key: "Radius" },
  { name: "AoA (init mass)", desc: "Angle of Atack (degree)", key: "aoa" },
  { name: "Roll (init mass)", desc: "Roll (degree)", key: "roll" },
  { name: "Flaps (init mass)", desc: "Flaps position (0..1)", key: "flaps" },
];

var speedType = [
  { name: "IAS km/h", key: "IAS", value: "Indicated Air Speed (km/h)" },
  { name: "IAS kts", key: "IAS_kts", value: "Indicated Air Speed (kts)" },
  { name: "TAS km/h", key: "TAS", value: "True Air Speed (km/h)" },
  { name: "TAS kts", key: "TAS_kts", value: "True Air Speed (kts)" },
  { name: "Mach", key: "mach", value: "Mach" },
];

let kinds = [
  { name: "Main Planes" },
  { name: "Korean War", planes: ["MiG-15bis", "F-86F Sabre"] },
  { name: "World War II Planes", planes: ["Bf-109K-4", "FW-190A8", "FW-190D9", "I-16", "P-51D", "SpitfireLFMkIXCW", "SpitfireLFMkIX", "P-47D-40", "P-47D-30"] },
  { name: "Attack aircraft", planes: ["Su-25T", "Su-25", "A-10A", "A-10C", "A-10C_2"] },
];

let allPlanes = [...new Set(Data.map((item) => item.Plane))];
let otherKindPlanes = [...kinds[1].planes, ...kinds[2].planes, ...kinds[3].planes];
kinds[0].planes = allPlanes.filter((item) => !otherKindPlanes.includes(item));

for (let { actuals, version } of actualSet) {
  var act = {};
  for (let item of Data) {
    if (actuals.includes(item.Id)) (act[item.Plane] = act[item.Plane] || new Set()).add(item.Version);
  }

  for (let item of Data) {
    let a = act[item.Plane];
    if (a && a.has(item.Version)) item.Actual = version;
  }
}

/**
 * @typedef {object} PlaneMeasure
 * @property {number} Altitude
 * @property {number} [Donor]
 * @property {number} [DonorTill]
 * @property {string} [Flaps]
 * @property {number} Fuel
 * @property {number} TotalFuel
 * @property {number} CalcFuel
 * @property {number} Id
 * @property {number} Mass
 * @property {string} [Payload]
 * @property {string} Plane
 * @property {string} Version
 * @property {PlaneStep[]} values
 * @property {string} [Actual]
 */

/**
 * @typedef {object} PlaneStep
 * @property {number} [IAS]
 * @property {number} [TAS]
 * @property {number} [turn_rate]
 * @property {number} [turn_time]
 * @property {number} [aoa]
 * @property {number} [ny]
 * @property {number} [mach]
 * @property {number} [roll]
 * @property {number} [pitch]
 * @property {number} [flaps]
 * @property {number | string} [noseflap]
 * @property {number} [stick_Y]
 * @property {number} [GraphG]
 *
 */

export { actualVersion, defColors, tabs, speedType, kinds, Data };
