import ReactDOM from 'react-dom';


var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};


/**
 * Converts a SVG element to a PNG URL string
 * @param svg The SVG from a Recharts chart
 * @param fill The fill (background) color
 */
function svgToPng(svg, fill,shift) {
    var height = svg.height.baseVal.value;
    var width = svg.width.baseVal.value;
    return new Promise(function (resolve, reject) {
        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height+shift;
        var ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.fillStyle = fill;
            ctx.fillRect(0, 0, width, height+shift);
            var xml = new XMLSerializer().serializeToString(svg);
            var dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
            var img_1 = new Image(width, height);
            img_1.src = dataUrl;
            img_1.onload = function () {
                ctx.drawImage(img_1, 0, shift);
                resolve(canvas);
                //var imageData = canvas.toDataURL('image/png', 1.0);
                // console.log(imageData);
                //resolve(imageData);
            };
            img_1.onerror = function () { return reject(); };
        }
        else {
            reject();
        }
    });
}
/**
 * Returns a PNG URL string
 * @param chart - The Rechart chart to generate the PNG for
 * @param fill - The fill (background) color - optional; defaults to white
 */
export function getPngData(chart, fill,shift) {
    var _a, _b;
    if (fill === void 0) { fill = '#ffffff'; }
    return __awaiter(this, void 0, void 0, function () {
        var chartSVG;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    chartSVG = (_b = (_a = ReactDOM.findDOMNode(chart)) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b[0];
                    return [4 /*yield*/, svgToPng(chartSVG, fill,shift)];
                case 1: return [2 /*return*/, _c.sent()];
            }
        });
    });
}
