import React from "react";
import { Image, Divider,Table } from "antd";
import {Data} from "./Consts"

const columns = [
  {
    title: 'IAS',
    dataIndex: 'IAS',
    key: 'IAS',
  },
  {
    title: 'TAS',
    dataIndex: 'TAS',
    key: 'TAS',
  },
  {
    title: 'mach',
    dataIndex: 'mach',
    key: 'mach',
  },

  {
    title: 'ny',
    dataIndex: 'ny',
    key: 'ny',
  },

  {
    title: 'turn_rate',
    dataIndex: 'turn_rate',
    key: 'turn_rate',
  },
  
  {
    title: 'turn_time',
    dataIndex: 'turn_time',
    key: 'turn_time',
  },

  {
    title: 'aoa',
    dataIndex: 'aoa',
    key: 'aoa',
  },

  {
    title: 'pitch',
    dataIndex: 'pitch',
    key: 'pitch',
  },

  {
    title: 'roll',
    dataIndex: 'roll',
    key: 'roll',
  },
];



let example = Data.find(item=>item.Id===84);



export default function (props) {
  return (
    <div>
     
      <div style={{width:"960px"}} className="SkyFighters" >
        <div><img alt="Sky Fighters 1" src="/SkyFighters01.jpg" width="640px"/></div>
        
        <div><img alt="Sky Fighters 2" src="/SkyFighters02.jpg" width="640px"/></div>
        
        <div><img alt="Sky Fighters 3" src="/SkyFighters03.jpg" width="640px"/></div>
        
      </div>
      <h3>Sustained turn data base of DCS World planes</h3>
      <div>You can see precision example of measure turn time for IAS 500 kmh, altitude 20m. </div>
      <div>
        <iframe
          width="960"
          height="540"
          src="https://www.youtube.com/embed/gBNYv-as4no"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div>For collect data used step of change speed (IAS) 10km/h or 20km/h.</div>
      <Divider />
      <div>Checking measure precision on TacView</div>
      <Image src="/TacView.png" width="960px"></Image>
      
      <Divider />
      <div>Example "RAW data", getting from the DCS World simulator for each airplane</div>
      <Table style={{width:"960px"}} size="small" pageSize={10} dataSource={example.values} columns={columns} />
      <Divider />
      Diagrams was get using "GetData Graph Digitizer"
      <div>
        <Image src="/digitizer.png" width="640px"></Image>
      </div>
      <div>
        <a href="https://www.digitalcombatsimulator.com/ru/">DCS World</a> turn test data. Maked by totmacher aka TOT_53{" "}
        <a href="mailto:wump@yandex.ru">wump@yandex.ru</a>. Forum thread <a href="https://forums.eagle.ru/showthread.php?t=284553">here</a>
      </div>
    </div>
  );
}
