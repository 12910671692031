import React from "react";
import data from "./halloffame";
import { Table, Progress } from "antd";
import { Link } from "react-router-dom";

let maxTurnRate = data[0].TurnRate;

const columns = [
  { title: "Place", dataIndex: "Plane", key: "Plane", render: (item, row, index) => <>{index + 1}</> },
  { title: "Plane", dataIndex: "Plane", key: "Place", render: (Plane, Item) => <Link to={"/" + Item.Id + "-0,mach,turnrate"}>{Plane}</Link> },
  { title: "Version", dataIndex: "Version", key: "Version" },
  { title: "Payload", dataIndex: "Payload", key: "payload" },
  { title: "Flaps", dataIndex: "Flaps", key: "Flpas" },
  {
    title: "mach",
    dataIndex: "mach",
    key: "mach",
  },
  {
    title: "ny",
    dataIndex: "calcNy",
    key: "calcNy",
  },
  {
    title: "Turn Time (sec)",
    dataIndex: "TurnTime",
    key: "TurnTime",
  },
  {
    title: "Turn Rate (deg/sec)",
    dataIndex: "TurnRate",
    key: "TurnRate",
  },
  {
    title: "",
    dataIndex: "TurnRate",
    key: "TurnRate",
    render: (value) => <Progress style={{ width: "100px" }} percent={(value / maxTurnRate) * 100} showInfo={false} size="small"></Progress>,
  },
];

export default function () {
  return (
    <>
      <h4>
        Maximum turn rate with fuel quantity equal <b>ZERO</b>, ny limit to <b>9G</b>
      </h4>
      <Table pagination={false} style={{ width: "960px" }} size="small"  dataSource={data} columns={columns} />
    </>
  );
}
