import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, Line, ReferenceLine, LineChart } from "recharts";
import { Button, Table, Slider, Tabs, Tag, Space, Card } from "antd";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import { actualVersion, defColors, tabs, speedType, Data } from "./Consts";
import { getPngData } from "./getimage";
import FileSaver from "file-saver";
import { rectWithPoints } from "recharts/lib/util/CartesianUtils";

import DisplayMeasure from "./Classes/DisplayMeasure";

const { Column } = Table;
const { TabPane } = Tabs;

/**
 *
 * @param {String} str
 * @returns {DisplayMeasure[]}
 */
function strToVals(str) {


  if (str) {
    let params = str.split(",");

    

    let vals = params
      .filter((item) => !isNaN(+item[0]))
      .map((v) => {
        
        /** @type {[string,string | number]}} */
        let [id, calcFuel] = v.split("-");

        let plane = Data.find((elem) => elem.Id === +id);

       

        if (plane) {
          calcFuel = calcFuel === undefined ? plane.CalcFuel : +calcFuel;

          if (calcFuel < 0) calcFuel = 0;
          if (calcFuel > plane.TotalFuel) calcFuel = plane.TotalFuel;

          return new DisplayMeasure(plane, calcFuel);
        }
      });

      
    return vals;
  } else return [];
}

function Graphic(props, ref) {
  const { initValues, config, statical, title, addon, noAltitude } = props;

  let [selected, setSelected] = useState(() => strToVals(initValues));

  /*
  useEffect( ()=> {

 
     let curValues = selected.map((val) => val.Id + (val.CalcFuel === val.Fuel ? "" : "-" + val.CalcFuel), "");
     if (curValues!==initValues) {
        console.log("useEffect",curValues,initValues)
        setSelected(strToVals(initValues))
     }
    
  },[initValues])
*/

  let [colors, setColors] = useState(defColors); //TODO

  let [tab, setTab] = useState(() => {
    //TODO
    let params = initValues?.split(",");

    let tab = params
      ? Math.max(
          tabs.findIndex((tab) => params.includes(tab.key.toLowerCase())),
          0
        )
      : 0;

    return tab;
  });

  let chart = useRef();
  let animations = [];

  useEffect(() => {
    // console.log("useEffect");
    // return;

    if (statical) return;

    var params = selected.map((val) => val.src.Id + (val.CalcFuel === val.src.Fuel ? "" : "-" + val.CalcFuel), "");

    if (params.length > 0) {
      if (config.speed > 0) params.push(speedType[config.speed].key);
      if (config.zoom) params.push("zoom");
      if (config.lbs) params.push("lbs");
      if (tab > 0) params.push(tabs[tab].key.toLowerCase());
    }

    window.history.replaceState("", "", "/" + params.join(","));
  }, [config, selected, tab]);


  useEffect(() => {
    console.log("------> Config Changed");
   
  }, [config]);



  useImperativeHandle(ref, () => ({
    AddItem(item) {
      if (!item.children) setSelected([...selected, new DisplayMeasure(item)]);
      else {
        setSelected([...selected, ...item.children.map((c) => new DisplayMeasure(c))]);
      }
    },

    Reset() {
      setSelected([]);
    },
  }));

  function massStr(mass) {
    let c = 2.20462;
    return config.lbs ? Math.round(mass * c) + "lbs" : mass + "kg";
  }

  let tabInfo = tabs[tab];

  //TODO
  function handleDelete(idx) {
    var newdata = selected.filter((item, i) => idx !== i);
    setSelected(newdata);
  }

  /**
   *
   * @param {DisplayMeasure} row
   * @param {number} value
   */
  function changeFuel(row, value) {
    row.SetFuelCount(value);
    setSelected([...selected]);
  }

  /**
   *
   * @param {DisplayMeasure} record
   */
  function handleReset(record) {
    record.ResetFuelCount();
    setSelected([...selected]);
  }

  function changeColor(index, color) {
    setColors(colors.map((cl, idx) => (idx === index ? color.color : cl)));
  }

  const handleDownload = async () => {
    let isAnimation = () => {
      for (let i = 0; i < selected.length; i++) if (animations[i]) return true;
      return false;
    };

    if (isAnimation) {
      await new Promise((res) => {
        var t = setInterval(() => {
          if (!isAnimation()) {
            clearInterval(t);
            res();
          }
        }, 500);
      });
    }

    let rowHeight = 22;
    const pngData = await getPngData(chart.current, "#F0F0F0", rowHeight * (selected.length + 1) + 8);

    var ctx = pngData.getContext("2d");
    //
    ctx.font = "14px Arial";

    let y = rowHeight;
    let i = 0;

    ctx.fillStyle = "navy";
    ctx.font = "bold 14px Arial";
    var alt = selected?.[0].src.Altitude || 0;
    ctx.fillText(tabInfo.name + " - Altitude " + alt + "m/" + Math.round(alt * 3.2808399) + "ft", 60, y);

    ctx.font = "14px Arial";

    y += rowHeight;

    for (let item of selected) {
      ctx.fillStyle = colors[i++];
      ctx.fillRect(10, y + 3 - rowHeight + 8, 40, rowHeight - 2 - 12);

      ctx.fillStyle = "#0f0f0f";

      ctx.fillText(item.src.Plane, 60, y);
      ctx.fillText(
        `${massStr(item.CalcFuel)} Fuel (${Math.round((item.CalcFuel / item.src.TotalFuel) * 100)}%) ${massStr(
          item.src.Mass - item.src.Fuel + item.CalcFuel
        )} Total`,
        180,
        y
      );
      ctx.fillText("flaps:" + item.src.Flaps, 420, y);
      ctx.fillText("payload:" + item.src.Payload, 520, y);
      ctx.fillText("v." + item.src.Version, 850, y);
      y += rowHeight;
    }

    var imageData = pngData.toDataURL("image/png", 1.0);

    let fn = tabInfo.name + " - " + selected.map((s) => s.src.Plane).join(",") + ".png";

    FileSaver.saveAs(imageData, fn);
  };

  if (selected.length === 0) return null;

  // console.log(tabs[tab].key.toLowerCase(),addon[tabs[tab].key.toLowerCase()]);

  /*
color: "#4472C4"
dataKey: "calcNy"
fill: "#fff"
formatter: undefined
name: "AV8BNA (50% fuel) flaps:Auto"
payload: {IAS_kts: 250, TurnTime: 25.05, TurnRate: 14.37, calcNy: 3.46, Radius: 538.27, …}
stroke: "#4472C4"
strokeWidth: 3
type: undefined
unit: undefined
value: 3.46
 
 Radius: 596.92
TurnRate: 14.21
TurnTime: 25.34
aoa: 13.66
calcNy: 3.77
flaps: 0.32
mach: 0.44
roll: 75.99
*/

  /**
   *
   * @param {number} value
   * @returns
   */
  function V(value) {
    return <b>{value.toFixed(2)}</b>;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    //  console.log(payload);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ background: "#E0E0E0E0", boxShadow: "1px 1px 4px gray", padding: "4px" }}>
          <p className="desc">
            <b>{label}</b> {speedType[config.speed].name}
          </p>

          {payload.map((p, idx) =>
            p.dataKey === "calcNy" ? (
              <div key={idx} className="label" style={{ color: p.stroke }}>{`${p.name} - ny: ${p.value.toFixed(2)} - Turn Rate: ${p.payload.TurnRate.toFixed(
                2
              )}`}</div>
            ) : (
              <div key={idx} className="label" style={{ color: p.stroke }}>{`${p.name} - ${p.dataKey}: ${p.value.toFixed(2)} - ny: ${p.payload.calcNy.toFixed(
                2
              )}`}</div>
            )
          )}
        </div>
      );
    }

    return null;
  };

  let displayTitle = noAltitude ? title : title+", Altitude: "+selected[0].src.Altitude+"meters ("+Math.round(selected[0].src.Altitude/10*3.2808)*10 +" feet)";
  return (
    <Card
      hoverable
      title={displayTitle}
      style={{ width: "1000px" }}
      size="small"
      extra={
        <Space>
          <Button onClick={handleDownload}>Download</Button>
          {!statical && <Button onClick={() => setSelected([])}>Clear</Button>}
        </Space>
      }>
      <div>
        <Table size="small" bordered={true} pagination={false} dataSource={selected}>
          <Column
            title="Plane"
            dataIndex={["src", "Plane"]}
            render={(text, record, index) => (
              <ColorPicker onClose={(color) => changeColor(index, color)} color={colors[index]}>
                <Tag color={colors[index]}>{text}</Tag>
              </ColorPicker>
            )}
          />

          <Column
            title="Mass"
            dataIndex="Mass"
            render={(item, row) => (
              <div>
                {massStr(row.CalcFuel)} Fuel ({Math.round((row.CalcFuel / row.src.TotalFuel) * 100)}%), {massStr(row.src.Mass - row.src.Fuel + row.CalcFuel)}{" "}
                Total
              </div>
            )}
          />
          {!statical && (
            <Column
              title="Fuel"
              width="320px"
              dataIndex="Fuel"
              render={(item, row) => <Slider min={0} max={row.src.TotalFuel} onChange={(value) => changeFuel(row, value)} value={row.CalcFuel} />}
            />
          )}

          <Column title="Flaps" dataIndex={["src", "Flaps"]} />

          <Column title="Payload" dataIndex={["src", "Payload"]} />

          <Column title="Version" dataIndex={["src", "Version"]} render={(v) => (actualVersion.includes(v) ? <b>{v}</b> : <>{v}</>)} />

          {!statical && (
            <Column
              title=""
              render={(text, record, index) => (
                <Space>
                  <Button type="dashed" onClick={() => handleReset(record)}>
                    Reset
                  </Button>
                  <Button type="dashed" onClick={() => handleDelete(index)}>
                    Del
                  </Button>
                </Space>
              )}
            />
          )}
        </Table>
      </div>

      <Tabs defaultActiveKey={tab.toString()} type="line" onChange={(tab) => setTab(+tab)}>
        {tabs.map((tab, i) => (
          <TabPane tab={tab.name} key={i}></TabPane>
        ))}
      </Tabs>

      <div style={{ width: "960px", textAlign: "center" }}>
        <b>{tabInfo.desc}</b>
      </div>

      <LineChart
        width={960}
        height={600}
        ref={chart}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine y={0} stroke="black" />

        <XAxis
          scale={"linear"}
           
          domain={[2000, "auto"]}
          label={{ offset: -8, position: "insideBottom", value: speedType[config.speed].value, fill: "Gray" }}
          dataKey={speedType[config.speed].key}
          type="number"
          allowDuplicatedCategory={false}
        />
        <YAxis
          scale={"linear"}
          domain={[config.zoom ? 1000 : 0, 1]}
          label={{ offset: 16, value: tabInfo.desc, angle: -90, position: "insideLeft", fill: "Gray" }}
        />

        <Tooltip content={CustomTooltip} />

        {/*  <Tooltip itemStyle={{fontWeight:"bold"}} contentStyle={{background:"#E0E0E0E0",boxShadow:"1px 1px 4px gray"}} />
         */}

        {selected.map((item, i) => (
          <Line
            key={i}
            onAnimationEnd={() => (animations[i] = false)}
            onAnimationStart={() => (animations[i] = true)}
            dot={false}
            animationDuration={1500}
            stroke={colors[i]}
            data={item["values_" + speedType[config.speed].key]}
            name={item.src.Plane + " (" + Math.round((item.CalcFuel / item.src.TotalFuel) * 100) + "% fuel) flaps:" + item.src.Flaps}
            type="monotone"
            dataKey={tabInfo.key}
            strokeWidth={3}
          />
        ))}

        {addon && addon[tabs[tab].key.toLowerCase()]}
      </LineChart>
    </Card>
  );
}

export default forwardRef(Graphic);
