
class Spline {
  constructor(xs, ys) {
    this.xs = xs;
    this.ys = ys;
  }

  approximation(p1, p2, x) {
    let dx = this.xs[p2] - this.xs[p1],
      dx1 = x - this.xs[p1],
      dy = this.ys[p2] - this.ys[p1];

    return this.ys[p1] + (dy * dx1) / dx;
  }

  at(x) {
    if (x < this.xs[0]) return this.approximation(0, 1, x);

    if (x > this.xs[this.xs.length - 1]) return this.approximation(this.xs.length - 2, this.xs.length - 1, x);

    for (let i = 0; i < this.xs.length; i++) {
      if (x >= this.xs[i] && x <= this.xs[i + 1]) {
        return this.approximation(i, i + 1, x);
      }
    }
  }
}


/**
 * Интерполяция
 * @param {object} values
 * @param {string} field1
 * @param {string[]} fields
 * @param {number} step
 * @returns {import("./DisplayMeasure").DisplayMeasureStep[]}
 */
function spline(values, field1, fields,step) {
  let splines = {};

  for (let field2 of fields) {
    splines[field2] = new Spline(
      values.map((v) => v[field1]),
      values.map((v) => v[field2])
    );
  }

  let min = Math.floor(values[0][field1] / step) * step;
  let max = Math.ceil(values[values.length - 1][field1] / step) * step;

  var newValues = [];

  var tr2 = (x) => Math.round(x * 100) / 100;

  var tr3 = (x) => Math.round(x * 1000) / 1000;

  for (let i = min; i <= max; i += step) {
    let o = {};
    o[field1] = tr2(i);



    for (let field2 of fields) {


      o[field2] = tr2(splines[field2].at(i));
    }

    newValues.push(o);
  }

  // @ts-ignore
  return newValues;
}

//module.exports=

export default  spline
